.episode {
  margin: auto !important;
  width: 95%;
  max-width: 1000px;
}

.episode-card-container {
    margin: 0rem 1rem;
    padding: 0;
}

img.episode-play-pause {
  width: 90%;
  max-width: 120px;
  cursor: pointer;
}

span.episode-play-pause {
  width: 55%;
  padding-top: 55%;
  height: auto;
  border-width: 0.5rem;
  box-sizing: content-box;
}

.episode-play-pause-spinner-container {
  margin-left: 0.75rem;
}

.audio-date {
  text-align: right;
}

.go-to-snippet, .remove-from-library {
  width: 30px;
}

@media only screen and (max-width: 765px) {
  .podcast-card-body-container {
    width: 75%;
  }
}

@media only screen and (max-width: 600px) {
  .podcast-card-body .card-title, .episode-card-body .card-title {
    font-size: 18px;
  }

  .podcast-card-body .card-text, .episode-card-body .card-text {
    font-size: 14px;
  }

  .podcast-card-body .card-subtitle, .episode-card-body .card-subtitle {
    font-size: 14px;
  }

  .podcast-card-body, .episode-card-body {
    padding: 12px !important;
  }

  .podcast-card-body-container {
    width: 75%;
  }

  .episode-card-body-container {
    width: 85%;
  }

  .go-to-snippet, .remove-from-library {
    width: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .podcast-card-body .card-title, .episode-card-body .card-title {
    font-size: 16px;
  }

  .podcast-card-body .card-text, .episode-card-body .card-text {
    font-size: 14px;
  }

  .podcast-card-body .card-subtitle, .episode-card-body .card-subtitle {
    font-size: 14px;
  }

  .podcast-card-body, .episode-card-body {
    padding: 8px 4px !important;
  }

  .podcast-card-body-container {
    width: 65%;
    padding: 0px 4px;
  }

  .episode-card-body-container {
    width: 75%;
  }
}
