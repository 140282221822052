.facebook-login-button {
  background-color: rgb(54,85,148) !important;
}

.google-login-button {
  background-color: rgb(255, 255, 255) !important;
  color: rgba(0, 0, 0, 0.54) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
  border: 1px solid transparent !important;
}

.facebook-login-text, .google-login-text {
  margin-left: 7px;
}

.oauth-divider {
  width: 100%;
}

#forgot-password-link {
  padding: 0.5rem;
  font-size: 0.9rem;
  display: block;
}

dd {
  display: list-item;
  padding-inline-start: 1ch;
}

dd.validation-failed {
  list-style-type: "🚫";
}

dd.validation-succeeded {
  list-style-type: "✅";
}
