#snippr-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

#snippr-body {
  flex: 1 1 auto;
  overflow-y: scroll;
}

#snippr-tutorial {
  width: 100%;
  height: 300px;
  max-width: 600px;
}

.navbar-snippr {
  font-family: 'Mada', sans-serif;
  font-size: 18px;
}

.submit-button {
  background-color: #4e54c8 !important;
  border-color: #4e54c8 !important;
  display: flex !important;
  height: 40px !important;
  justify-content: center !important;
  align-items: center !important;
}

.clearfix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-column {
  float: left;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.episode-details {
  width: 90%;
}
