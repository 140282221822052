.bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bar__progress {
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
  height: 6px;
  margin: 20px 0px 20px 0px;
  border-radius: 15px;
  background: rgba(0,0,0,.3);
  cursor: pointer;
}

.bar__progress__knob__container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar__progress__knob {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  box-shadow:
  0px 1px 1px rgba(000,000,000,0.5),
  inset 1px 2px 0px rgba(255,255,255,0.4);
  border: 1px solid #4e54c8;

  background: -moz-linear-gradient(
  top,
  #4e54c8 0%,
  #6164d2 50%,
  #8079eb 92%,
  #8f94fb);

  background: -webkit-gradient(
    linear, left top, left bottom,
    from(#4e54c8),
    color-stop(0.50, #6164d2),
    color-stop(0.92, #8079eb),
    to(#8f94fb));

  z-index: 100;
  cursor: pointer;
}

.bar__progress__knob:hover {
  border-width: 2px;
}

.bar__snipping__knob__container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

#audio-progress-knob  {
  touch-action: none;
  width: 23px;
  height: 23px;
}

#snip-start-knob {
  position: absolute;
  right: 100%;
  height: 50px;
  touch-action: none;
}

#snip-stop-knob {
  position: absolute;
  left: 100%;
  height: 50px;
  touch-action: none;
}

.bar__snip__time {
  position: absolute;
  bottom: 30px;
  border-radius: 5px;
  background-color: #4e54c8;
  color: white;
  padding: 2px 5px 2px 5px;
  font-size: 13px;
  cursor: auto;
}

.snip__start__time {
  right: 100%;
  margin-right: 10px;
}

.snip__stop__time {
  left: 100%;
  margin-left: 10px;
}

.bar__snip__start__knob__time {
  position: absolute;
  right: 5px;
  color: rgb(100, 105, 209);
  top: 14px;
  font-size: 14px;
}

.bar__snip__stop__knob__time {
  position: absolute;
  left: 5px;
  color: rgb(100, 105, 209);
  top: 14px;
  font-size: 14px;
}

.bar__time {
  display: flex;
  justify-content: center;
  margin: 0px 7px 0px 7px
}

.bar__time.sm {
  width: 4rem;
}

.bar__time.lg {
  width: 7rem;
}

@media only screen and (max-width: 600px) {
  .expand-collapse-button{
    width: 15px !important;
    margin: 2px !important;
  }

  #current-audio-player {
    padding: 5px 10px 5px 10px !important;
  }

  .player__button {
    padding: 2px 6px 2px 6px !important;
  }

  .bar__progress {
    width: 70%;
    margin: 20px 5px 20px 5px;
  }

  .bar__time {
    padding: 0px 2px 0px 2px;
    margin: 0px;
  }

  .bar__time.sm {
    width: 70px;
    font-size: 13px;
  }

  .bar__time.lg {
    width: 95px;
    font-size: 13px;
  }
}

.snipping-container {
  height: 75px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.button {
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 500px) {
  .button {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 415px) {
  .button {
    width: 30px;
    height: 30px;
  }
}

#snippr-audio-container {
  background-color: rgb(231, 231, 231);
}

#current-audio-player {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}

#current-audio-podcast-name {
  text-align: center;
  font-size: 12px;
  margin: 2px 5px 0px 5px
}

.expand-collapse-button {
  width: 20px;
  margin: 5px;
}

#forward-10-button {
  width: 40px;
  margin-left: 10px
}

#rewind-10-button {
  width: 40px;
  margin-right: 10px
}

.thumbnail-skeleton {
  width: 100%;
  padding-top: 100%;
}

/*
.button-options {
  width: 20px;
  height: 30px;
}

div.menu-options > .dropdown-toggle:after {
  content: none;
  display: none;
}

.start-sn-arrow:after {
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 25px solid #6A0136;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
}

.library-indicator-remove-container {
  display: flex;
  align-items: center;
}
*/
